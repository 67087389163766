import React from 'react';

import Link from '@atlaskit/link';

// jest is not working with const or enum, so we need to use string literals
// import { AssistanceServiceMessageTemplate } from '@atlassian/ai-issue-create';

import { fg } from '@confluence/feature-gating';

import type { SuggestedIssuesErrorMessage } from '../__types__/IssueCreateSidePanelBody';
import { messages } from '../messages';

type AiIssueCreateErrorMessage = {
	errorMessage: (typeof messages)[keyof typeof messages];
	errorMessageValues: any;
	hideTryAgain: boolean;
};

export const handleAiIssueCreateError = (
	error: SuggestedIssuesErrorMessage,
): AiIssueCreateErrorMessage | undefined => {
	if (!fg('jira_ai_hide_try_again_on_ai_issue_create_errors')) {
		return;
	}

	let errorMessage = fg('confluence-issue-terminology-refresh')
		? messages.singleCreateAiErrorDescriptionIssueTermRefresh
		: messages.singleCreateAiErrorDescription;
	let errorMessageValues;
	let hideTryAgain = false;

	if (
		error.message_template !== undefined &&
		['ACCEPTABLE_USE_VIOLATIONS', 'ACCEPTABLE_USE_EVALUATION_EXCEPTION'].includes(
			error.message_template,
		)
	) {
		errorMessage = fg('confluence-issue-terminology-refresh')
			? messages.ethicalViolationErrorDescriptionIssueTermRefresh
			: messages.ethicalViolationErrorDescription;
		errorMessageValues = {
			link: (anchorText: React.ReactNode[]) => (
				<Link
					href="https://www.atlassian.com/legal/acceptable-use-policy"
					target="_blank"
					rel="noopener noreferrer"
				>
					{anchorText}
				</Link>
			),
		};
		hideTryAgain = true;

		return {
			errorMessage,
			errorMessageValues,
			hideTryAgain,
		};
	}

	if (error.message_template === 'RATE_LIMIT') {
		errorMessage = fg('confluence-issue-terminology-refresh')
			? messages.rateLimitErrorDescriptionIssueTermRefresh
			: messages.rateLimitErrorDescription;
		errorMessageValues = {
			link: (anchorText: React.ReactNode[]) => (
				<Link
					href="https://support.atlassian.com/organization-administration/docs/excessive-use-in-atlassian-intelligence/"
					target="_blank"
					rel="noopener noreferrer"
				>
					{anchorText}
				</Link>
			),
		};
		hideTryAgain = true;

		return {
			errorMessage,
			errorMessageValues,
			hideTryAgain,
		};
	}

	if (error.message_template === 'FE_REQUEST_EXCEEDING_TOKEN_LENGTH') {
		errorMessage = fg('confluence-issue-terminology-refresh')
			? messages.exceedingTokenLimitDescriptionIssueTermRefresh
			: messages.exceedingTokenLimitDescription;
		hideTryAgain = true;

		return {
			errorMessage,
			errorMessageValues,
			hideTryAgain,
		};
	}

	if (error.message_template === 'NO_ANSWER_SUGGESTED_ISSUES') {
		errorMessage = fg('confluence-issue-terminology-refresh')
			? messages.noAnswerSuggestedIssuesIssueTermRefresh
			: messages.noAnswerSuggestedIssues;
		hideTryAgain = true;
	}

	return {
		errorMessage,
		errorMessageValues,
		hideTryAgain,
	};
};
