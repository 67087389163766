import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { GicAnywhere, type IssueCreateEmbedSuccessPayload } from '@atlassian/gic-anywhere';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';

import type {
	IssueCreateSidePanelBodyProps,
	SuggestedIssuesErrorMessage,
} from './__types__/IssueCreateSidePanelBody';
import {
	useIssueCreateSidePanelContext,
	type IssueCreateSidePanelContextType,
} from './providers/IssueCreateSidePanelContextProvider';
import { IssueCreateAiLoading } from './IssueCreateAiLoading';
import { AiCreationError } from './AiCreationError';
import { messages } from './messages';
import {
	constructSmartlinkTag,
	insertContentAtSelectionEnd,
	refreshPageWithTimeout,
} from './utils/issueInsertionUtils';
import { useTriggerFlagIssueCreated } from './utils/useTriggerFlagIssueCreated';
import {
	getPersistedOverrideCloudId,
	saveCloudIdToLocalStorage,
} from './utils/localStorageHelpers';
import { IssueCreateWithAiToggle } from './IssueCreateWithAiToggle';
import { ConfluenceAiIssueCreate } from './ConfluenceAiIssueCreate';
import { useIsAIIssueCreationAllowed } from './utils/useIsAIIssueCreationAllowed';
import { handleAiIssueCreateError } from './utils/handleAiIssueCreateError';

const aiContentWrapperBaseStyles = xcss({
	display: 'inherit',
	flexGrow: 1,
});
const aiContentWrapperHiddenStyles = xcss({ display: 'none' });

export const IssueCreateSidePanelBody = ({ onClose, flags }: IssueCreateSidePanelBodyProps) => {
	const { cloudId } = useSessionData();
	const [state, actions] = useIssueCreateSidePanelContext();
	const {
		contentId,
		contentType,
		summary,
		descriptionAdf,
		aiState,
		isCreatedWithAi,
		highlightedText,
		insertStorageFragmentRequestPayload,
		totalRetries,
		firstAttempt,
		aiAnalyticsContext,
	} = state;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { trackAIInteractionInit, trackAIResultError, trackAIResultView, trackAIResultAction } =
		useAIEventsInstrumentation();
	const isAIIssueCreationAllowed = useIsAIIssueCreationAllowed();

	const aiError = aiState === 'error';

	const persistedOverrideCloudId = getPersistedOverrideCloudId();

	// no need to override if it's the same site
	const overrideCloudIdForSitePicker =
		persistedOverrideCloudId !== cloudId ? persistedOverrideCloudId : undefined;

	const [showCreateWithAiToggle, setShowCreateWithAiToggle] = useState(false);
	const defaultErrorMessage = fg('confluence-issue-terminology-refresh')
		? messages.singleCreateAiErrorDescriptionIssueTermRefresh
		: messages.singleCreateAiErrorDescription;
	const [errorMessage, setErrorMessage] =
		useState<(typeof messages)[keyof typeof messages]>(defaultErrorMessage);
	const [errorMessageValues, setErrorMessageValues] = useState<Record<string, ReactNode>>();
	const [hideTryAgain, setHideTryAgain] = useState(false);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'linkCreateScreen',
				attributes: {
					isManuallyCreated: !isCreatedWithAi,
					isCreatedWithAi,
				},
				source: 'confluenceHighlightIssueCreate',
			},
		}).fire();
	}, [isCreatedWithAi, createAnalyticsEvent]);

	if (!fg('fix_wrong_attributes_for_ai_issue_create_analytics')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (totalRetries > 0) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						source: 'issueCreateSidePanelErrorScreen',
						action: 'retry',
						actionSubject: 'issueCreate',
						attributes: {
							createManually: !isCreatedWithAi,
							tryAgainWithAi: isCreatedWithAi,
							totalRetries,
							firstAttempt,
							...aiAnalyticsContext,
						},
					},
				}).fire();
			}
		}, [isCreatedWithAi, createAnalyticsEvent, totalRetries, firstAttempt, aiAnalyticsContext]);
	}

	const isAiLoading = aiState === 'loading';
	const triggerFlag = useTriggerFlagIssueCreated(flags);

	const handleTryAgain = useCallback(() => {
		void actions.setTotalRetries();
		void actions.setFirstAttempt(false);
		void actions.setAiState('idle');

		// trigger a new AI interaction init event retries
		if (fg('fix_wrong_attributes_for_ai_issue_create_analytics')) {
			trackAIInteractionInit({
				attributes: {
					totalRetries: totalRetries + 1,
					firstAttempt: false,
				},
			});
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'issueCreateSidePanelErrorScreen',
					action: 'retry',
					actionSubject: 'issueCreate',
					attributes: {
						createManually: !isCreatedWithAi,
						tryAgainWithAi: isCreatedWithAi,
						totalRetries: totalRetries + 1,
						firstAttempt: false,
						...aiAnalyticsContext,
					},
				},
			}).fire();
		} else {
			trackAIInteractionInit({
				attributes: {
					totalRetries,
					firstAttempt,
				},
			});
		}
	}, [
		actions,
		aiAnalyticsContext,
		createAnalyticsEvent,
		firstAttempt,
		isCreatedWithAi,
		totalRetries,
		trackAIInteractionInit,
	]);

	const handleCreateManually = useCallback(() => {
		void actions.setIsCreatedWithAi(false);
		void actions.setAiState('idle');
		setShowCreateWithAiToggle(false);
	}, [actions]);

	const handleCreateWithAi = useCallback(() => {
		void actions.setIsCreatedWithAi(true);
		void actions.setAiState('loading');
		setShowCreateWithAiToggle(false);
	}, [actions]);

	const handleCreateManuallyAfterError = useCallback(() => {
		void actions.setTotalRetries();
		void actions.setFirstAttempt(false);
		if (fg('fix_wrong_attributes_for_ai_issue_create_analytics')) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'issueCreateSidePanelErrorScreen',
					action: 'retry',
					actionSubject: 'issueCreate',
					attributes: {
						createManually: true,
						tryAgainWithAi: false,
						totalRetries: totalRetries + 1,
						firstAttempt: false,
						...aiAnalyticsContext,
					},
				},
			}).fire();
		}
		handleCreateManually();
	}, [actions, handleCreateManually, createAnalyticsEvent, totalRetries, aiAnalyticsContext]);

	const onIssueCreateInit = useCallback(() => {
		if (fg('confluence_single_issue_create_ai_toggle')) {
			if (isAIIssueCreationAllowed) {
				setShowCreateWithAiToggle(true);
			}
		}
	}, [isAIIssueCreationAllowed]);

	const onAiIssueCreateInit = useCallback(() => {
		onIssueCreateInit();
		const analyticAttributes = { attributes: aiAnalyticsContext };
		trackAIResultView({
			actionSubjectId: 'aiIssueCreatOnInitComplete',
			...analyticAttributes,
		});
	}, [trackAIResultView, aiAnalyticsContext, onIssueCreateInit]);

	const isSuggestedIssuesErrorMessage = (error: any): error is SuggestedIssuesErrorMessage => {
		return error && typeof error === 'object' && 'message_template' in error;
	};

	const onFailure = useCallback(
		(error: SuggestedIssuesErrorMessage | Error | unknown) => {
			if (fg('jira_ai_hide_try_again_on_ai_issue_create_errors')) {
				// reset
				if (hideTryAgain) {
					setHideTryAgain(false);
					setErrorMessage(
						fg('confluence-issue-terminology-refresh')
							? messages.singleCreateAiErrorDescriptionIssueTermRefresh
							: messages.singleCreateAiErrorDescription,
					);
					setErrorMessageValues({});
				}

				if (isSuggestedIssuesErrorMessage(error)) {
					const aiIssueCreateError = handleAiIssueCreateError(error);

					if (aiIssueCreateError !== undefined) {
						setErrorMessage(aiIssueCreateError.errorMessage);
						setErrorMessageValues(aiIssueCreateError.errorMessageValues);
						setHideTryAgain(aiIssueCreateError.hideTryAgain);
					}
				}
			}

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'confluenceHighlightIssueCreate',
					action: 'error',
					actionSubject: 'globalIssueCreate',
					attributes: {
						isManuallyCreated: !isCreatedWithAi,
						isCreatedWithAi,
						error,
					},
				},
			}).fire();
			if (isCreatedWithAi) {
				trackAIResultError(
					{
						aiErrorMessage: (error as Error).message,
					},
					{
						attributes: {
							totalRetries,
							firstAttempt,
							...aiAnalyticsContext,
						},
					},
				);
			}
		},
		[
			createAnalyticsEvent,
			isCreatedWithAi,
			hideTryAgain,
			trackAIResultError,
			totalRetries,
			firstAttempt,
			aiAnalyticsContext,
		],
	);

	const onSuccess = useCallback(
		(payload: IssueCreateEmbedSuccessPayload) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'confluenceHighlightIssueCreate',
					action: 'created',
					actionSubject: 'issue',
					actionSubjectId: 'confluenceIssueCreate',
					attributes: {
						isManuallyCreated: !isCreatedWithAi,
						isCreatedWithAi,
						isCreatedFromTable: false,
					},
				},
			}).fire();
			if (isCreatedWithAi) {
				trackAIResultAction('issue created', {
					attributes: {
						totalRetries,
						firstAttempt,
						issueId: payload?.issueId || '',
						...aiAnalyticsContext,
					},
				});
			}
			triggerFlag(
				payload,
				'success-circle',
				fg('confluence-issue-terminology-refresh')
					? messages.sidePanelSingleIssueCreateSuccessFlagTitleIssueTermRefresh
					: messages.sidePanelSingleIssueCreateSuccessFlagTitle,
			);

			// Save the cloud id in localStorage if an issue is created
			saveCloudIdToLocalStorage(payload);

			if (insertStorageFragmentRequestPayload) {
				insertStorageFragmentRequestPayload.xmlModification = constructSmartlinkTag(payload.link);
				insertContentAtSelectionEnd(insertStorageFragmentRequestPayload)
					.then((data) => {
						// There is a case where the API response is OK, but the response data is false.
						// When this happens, the backend has trouble inserting the ticket to the page and we should show an error toast.
						if (data === false) {
							throw new Error('Failed to insert storage fragment');
						}

						return createAnalyticsEvent({
							type: 'sendTrackEvent',
							data: {
								source: 'confluenceHighlightIssueCreate',
								action: 'created',
								actionSubject: 'link',
								actionSubjectId: 'confluenceIssueCreate',
								attributes: {
									isManuallyCreated: !isCreatedWithAi,
									isCreatedWithAi,
								},
							},
						}).fire();
					})
					.then(() => {
						refreshPageWithTimeout();
					})
					.catch((_error) =>
						triggerFlag(payload, 'error', messages.sidePanelIssueCreateInsertionFailed),
					);
			}
		},
		[
			triggerFlag,
			insertStorageFragmentRequestPayload,
			createAnalyticsEvent,
			isCreatedWithAi,
			trackAIResultAction,
			aiAnalyticsContext,
			totalRetries,
			firstAttempt,
		],
	);

	const handleStateChange = useCallback(
		(aiFetchState: IssueCreateSidePanelContextType['aiState']) => {
			void actions.setAiState(aiFetchState);
		},
		[actions],
	);

	const onAiToggleChange = useCallback(
		(enabled: boolean) => {
			if (enabled) {
				trackAIInteractionInit();
				handleCreateWithAi();
			} else {
				handleCreateManually();
			}
		},
		[handleCreateWithAi, handleCreateManually, trackAIInteractionInit],
	);

	if (aiError) {
		return (
			<AiCreationError
				creationSource="confluenceHighlightIssueCreate"
				errorMessage={errorMessage}
				errorMessageValues={hideTryAgain ? errorMessageValues : undefined}
				onTryAgain={hideTryAgain ? undefined : handleTryAgain}
				onCreateManually={handleCreateManuallyAfterError}
			/>
		);
	}

	return (
		<>
			{showCreateWithAiToggle && <IssueCreateWithAiToggle onChange={onAiToggleChange} />}
			{isCreatedWithAi ? (
				<>
					{isAiLoading && <IssueCreateAiLoading />}
					<Box xcss={[aiContentWrapperBaseStyles, isAiLoading && aiContentWrapperHiddenStyles]}>
						<ConfluenceAiIssueCreate
							cloudId={cloudId}
							contentType={contentType}
							pageId={contentId}
							highlightedTextAdf={highlightedText}
							consumer="confluence-highlight-issue-create-ai"
							onInit={onAiIssueCreateInit}
							onSuccess={onSuccess}
							onFailure={onFailure}
							onClose={onClose}
							onStateChange={handleStateChange}
							overrideCloudIdForSitePicker={overrideCloudIdForSitePicker}
							setAiAnalyticsContext={actions.setAiAnalyticsContext}
						/>
					</Box>
				</>
			) : (
				<GicAnywhere
					cloudId={cloudId}
					consumer="confluence-highlight-issue-create"
					onInit={onIssueCreateInit}
					onSuccess={onSuccess}
					onClose={onClose}
					onFailure={onFailure}
					summary={summary}
					descriptionAdf={descriptionAdf}
					overrideCloudId={overrideCloudIdForSitePicker}
					isPayloadReady
				/>
			)}
		</>
	);
};
